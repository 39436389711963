import React, { useEffect, useState } from 'react';
import './Hero.scss';
import Logo from './evvolf.png';
import { Link as ScrollLink } from 'react-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Hero = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: true
    });

    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`hero ${isScrolled ? 'scrolled' : ''}`}>
      <div className='hero-bg'></div>
      <div className='hero-bg-up' data-aos='fade-down'></div>
      <div className={`header ${isScrolled ? 'scrolled-header' : ''}`}>
        <img src={Logo} alt='logo' className='logo-img' />
        <div className='menu-container'>
          <ul className='menu-list'>
            <li className='menu-item'><ScrollLink to="About" smooth={true} duration={500}>O mnie</ScrollLink></li>
            <li className='menu-item'><ScrollLink to="Projects" smooth={true} duration={500}>Realizacje</ScrollLink></li>
            <li className='menu-item'><ScrollLink to="Contact" smooth={true} duration={500}>Kontakt</ScrollLink></li>
          </ul>
        </div>
      </div>
      <div className='hello'>
      <h1
  data-aos='fade-up'
  className={`hero-title ${isScrolled ? 'hidden' : ''}`}
>
  TWOJA STRONA
</h1>
        <h2 data-aos='fade-down' className='hero-h2'>zdefiniowana na nowo</h2>
      </div>
    </div>
  );
};

export default Hero;
