import React, { useState, useEffect } from 'react';
import './Contact.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ReCAPTCHA from 'react-google-recaptcha';
// import { FaGithub, FaFacebook, FaLinkedin } from 'react-icons/fa';
import Contactbg from './bg-contact.png';
import axios from 'axios';

const ContactForm = () => {
  useEffect(() => {
    AOS.init({
      duration: 300,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [sending, setSending] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setRecaptchaError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      setRecaptchaError('Potwierdź, że nie jesteś robotem.');
      return;
    }

    setSending(true);

    try {
      const response = await axios.post(
        'https://formsubmit.co/ajax/e380d0fb335122e201062929a4fba700',
        formData
      );

      if (response.data.success) {
        console.log('Form submitted successfully:', formData);
        setFormSubmitted(true);
      } else {
        console.error('Form submission failed:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred during form submission:', error.message);
    } finally {
      setSending(false);
    }
  };

  return (
    <div className="contact" id='Contact'>
      <div data-aos="zoom-in-up">
        <div className="contact-form-container-header">
          <div className="screen-header-button close"></div>
          <div className="screen-header-button maximize"></div>
          <div className="screen-header-button minimize"></div>
        </div>
        <div className="contact-form-container">
          <div>
            <h1>kontakt</h1>
            <div className="contact-columns">
                <div>
                  <form onSubmit={handleSubmit} className="contact-form">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                      <label htmlFor="name">Imię i nazwisko</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      <label htmlFor="email">E-mail</label>
                    </div>
                    <div className="form-group">
                      <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      />
                      <label htmlFor="message">Wiadomość</label>
                    </div>
                    <div>
                      <ReCAPTCHA
                        sitekey="6LfeiEIpAAAAAFdcCKONr5Ux6jSkgof76gLqyirQ"
                        onChange={handleRecaptchaChange}
                        className="captcha"
                      />
                      {recaptchaError && (
                        <div className="error-message">{recaptchaError}</div>
                      )}
                    </div>
                    {sending ? (
                    <div className="sending-message"><p>Wiadomość jest wysyłana... Nie zamykaj okna...</p></div>
                  ) : (
                    <div>
                      {formSubmitted ? (
                        <div className="sending-message">
                          <p>Wiadomość została wysłana.</p>
                        </div>
                      ) : (
                        <button
                          type="submit"
                          className={`bn ${!recaptchaValue ? 'inactive' : ''}`}
                        >
                          WYŚLIJ
                        </button>
                      )}
                    </div>
                  )}
                </form>
              </div>
              <div className="contact-text">
                {/* <h3 className="ico">
                  <FaLinkedin />
                  <FaFacebook />
                  <FaGithub />
                </h3> */}
                <img src={Contactbg} alt="bg" className="bg-contact" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
