import React from 'react';
import './Port.scss';

import { FaChartBar } from "react-icons/fa6";
import { FaPencilRuler } from "react-icons/fa";
import { LuMonitorSmartphone } from "react-icons/lu";
import { FaGoogle } from "react-icons/fa";


const Port = () => {
    return (
        <div className='port'>
                        <h1>usługi</h1>
            <div className='port-container'>
                        <div className='port-item'>
                            <FaPencilRuler className="port-icon"/>
                            <h2>Projektowanie</h2>
                            <p>
                            Jako projektant graficzny i programista tworzę unikalny design, podkreślający charakter Twojej firmy, wyróżniający się spośród konkurencji.</p>
                        </div>
                        <div className='port-item'>
                            <FaChartBar className="port-icon"/>
                            <h2>SEO</h2>
                            <p>
                            Z myślą o skutecznej widoczności online, zoptymalizowuję strony pod kątem SEO, pomagając osiągać lepsze pozycje w wynikach wyszukiwania Google.</p>
                        </div>
                        <div className='port-item'>
                            <LuMonitorSmartphone className="port-icon"/>
                            <h2>Responsywność</h2>
                            <p>
                            Projektuję responsywne strony, zapewniające doskonałe doświadczenia użytkownikom na różnych urządzeniach, od komputerów stacjonarnych po smartfony.</p>
                        </div>
                        <div className='port-item'>
                            <FaGoogle className="port-icon"/>
                            <h2>Google Ads</h2>
                            <p>
                            Współpracuję z najnowszymi trendami w reklamie online, tworząc strony łatwo integrujące się z platformą Google Ads.</p>
                        </div>
            </div>
        </div>
        );
    }
    
    export default Port;