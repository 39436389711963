import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './More.scss';
import dev1 from './dev1.png';
import dev2 from './dev2.png';
import dev3 from './dev3.png';

const More = () => {

    useEffect(() => {
        AOS.init({
            duration: 800, 
            easing: 'ease-in-out', 
          });
    
      }, []);

    return (
            <div className='more' >
                <h1>DLACZEGO DEVVOLF?</h1>
                <div className='whyus-container' data-aos="flip-up">
                    <div className='whyus-item'>
                        <img src={dev1} className='whyus-img' alt='img'/>
                        <h3>
                        Doświadczenie I Ekspertyza
                        </h3>
                        <p>
                        Jestem doświadczonym specjalistą, dla którego tworzenie stron to pasja i zobowiązanie.</p>
                    </div>
                    <div className='whyus-item'>
                        <img src={dev2} className='whyus-img' alt='img'/>
                        <h3>
                        Indywidualne Podejście
                        </h3>
                        <p>
                        Rozumiem, że każda firma jest inna. Dlatego moje podejście jest indywidualne, dostosowane do potrzeb i celów Twojego biznesu.</p>
                    </div>
                    <div className='whyus-item'>
                        <img src={dev3} className='whyus-img' alt='img'/>
                        <h3>
                        Wsparcie I Utrzymanie
                        </h3>
                        <p>
                        Nie kończę współpracy po dostarczeniu strony. Oferuję również usługi wsparcia i utrzymania, abyś zawsze mógł liczyć na profesjonalną opiekę.</p>
                    </div>
                </div>
            </div>
        );
    }
    
    export default More;