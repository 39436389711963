import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './About.scss';
import Dev from './dev.png';
import { IoLogoJavascript, IoLogoReact, IoLogoCss3, IoLogoWordpress, IoLogoSass } from "react-icons/io5";


const About = () => {

    useEffect(() => {
        AOS.init({
            duration: 800,
            easing: 'ease-in-out',
          });
    
      }, []);

    return (
        <div id='About' className='about'>
            <img src={Dev} alt='dev' className='dev-img' data-aos='fade-right'/>
            <div className='about-text'  data-aos='fade-left'>
                <h1>HELLO</h1>
                <p>
                Jestem specjalistą ds. tworzenia stron internetowych, który skupia się na przekształcaniu mało znanych marek w rozpoznawalne poprzez skuteczne strategie SEO, reklamy internetowej i optymalizacji responsywności stron. Posiadam umiejętności w obszarze JavaScript, React i SASS, co umożliwia mi nie tylko efektywne implementowanie funkcji interaktywnych, ale również tworzenie estetycznych i nowoczesnych interfejsów. Moje doświadczenie obejmuje także wykonanie mniej skomplikowanych projektów opartych na WordPressie. Działając w obszarze projektowania interfejsów, dbam o perfekcyjne dostosowanie stron do różnych urządzeń, co przyczynia się do zwiększenia widoczności w wynikach wyszukiwania i poprawy doświadczenia użytkownika. Moje podejście skoncentrowane na wynikach pozwala firmom przechodzić od nieznanej marki do poziomu rozpoznawalności, wykorzystując potencjał SEO, reklam online oraz optymalizacji responsywności stron internetowych.</p>
                <div className='icos'><IoLogoJavascript /><IoLogoReact /><IoLogoCss3 /><IoLogoWordpress /><IoLogoSass/></div>
            </div>
      </div>
        );
    }
    
    export default About;