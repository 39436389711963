import React from 'react';
import './Projects.scss';
import ScrollCarousel from 'scroll-carousel-react';

import image1 from './img1.png';
import image2 from './img2.png';
import image3 from './img3.png';

const Projects = () => {
  const images = [image1, image2, image3];

  return (
    <div className='projects' id='Projects'>
    <h1 style={{textAlign:'right'}}>realizacje</h1>
    <ScrollCarousel
        autoplay
        autoplaySpeed={4}
        speed={5}
        smartSpeed={true}
        margin={15}
    >
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Project ${index + 1}`} className='projects-img' />
          </div>
        ))}
      </ScrollCarousel>
    </div>
  );
}

export default Projects;
