import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Loader from './Loader';
import Hero from './Hero.js';
import About from './About.js';
import More from './More.js';
import Port from './Port.js';
import Projects from './Projects.js';
import Contact from './Contact.js';
import Footer from './Footer.js';
import './index.scss';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise(resolve => setTimeout(resolve, 500));
      setIsLoading(false); 
    };

    fetchData();
  }, []);

  return (
    <React.StrictMode>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Hero />
          <About />
          <More />
          <Port />
          <Projects />
          <Contact />
          <Footer />
        </>
      )}
    </React.StrictMode>
  );
};

const rootElement = document.getElementById('root');
ReactDOM.createRoot(rootElement).render(<App />);
