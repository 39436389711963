import React from 'react';
import './Footer.scss'; 

function Footer() {
  return (
    <footer className="footer-container">
      <p>🫡 &copy; 2024 created by devvolf</p>
    </footer>
  );
}

export default Footer;
